import { StaticImage } from "gatsby-plugin-image"

const ExpertAndTeam = () => {
  return (
    <section className="container">
      <h2 className="expert-title">Our Experts and Team</h2>
      <div className="expert-wrapper">
        <div className="grid">
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_arini.jpg"
                />
              </div>
              <h2 className="grid-content-name">Arini Setiawati</h2>
              <h3 className="grid-content-title">Principal Investigator</h3>
            </div>
            <p className="grid-content-description font-justify">
              Prof. Dra. Arini Setiawati,Apt. PhD. is a Principal Investigator
              at Pharma Metric Labs. She is also a Lecturer at Department
              Pharmacology, Investigator at Clinical Study Unit, Faculty of
              Medicine, University of Indonesia, Jakarta and Consultant of
              National Agency of Drug and Food Control (NADFC) Indonesia for
              Clinical Trial and Bioavailability and Bioequivalency (BA/BE)
              Study.
            </p>
          </div>
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_franciscus.jpg"
                />
              </div>
              <h2 className="grid-content-name">Fransciscus D. Suyatna</h2>
              <h3 className="grid-content-title">Principal Investigator</h3>
            </div>
            <p className="grid-content-description font-justify">
              Prof.dr. Fransciscus D. Suyatna, MD, SpFK, PhD. is a Principal
              Investigator at Pharma Metric Labs. He is also a Lecturer at
              Department Pharmacology and Investigator at Clinical Study Unit,
              Faculty of Medicine, University of Indonesia, Jakarta.
            </p>
          </div>
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_hedi.jpeg"
                />
              </div>
              <h2 className="grid-content-name">Hedi R. Suwoto</h2>
              <h3 className="grid-content-title">Principal Investigator</h3>
            </div>
            <p className="grid-content-description font-justify">
              Prof. Hedi R. Dewoto, MD, SpFK is a Principal Investigator at
              Pharma Metric Labs. She is also a Lecturer at Department
              Pharmacology, Investigator at Clinical Study Unit, Faculty of
              Medicine, University of Indonesia, Jakarta and a member of Drug's
              Adverse Event Monitoring Committee (MESO) of National Agency of
              Drug and Food Control (NADFC), Indonesia.
            </p>
          </div>
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_metta.jpg"
                />
              </div>
              <h2 className="grid-content-name">Metta Sinta Sari Wiria</h2>
              <h3 className="grid-content-title">Principal Investigator</h3>
            </div>
            <p className="grid-content-description font-justify">
              Dra. Metta Sinta Sari Wiria, Apt, M.S. is a Principal Investigator
              at Pharma Metric Labs. She is also a Lecturer at Department
              Pharmacology and Bioanalytical expert at Faculty of Medicine,
              University of Indonesia, Jakarta.
            </p>
          </div>
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_suharti.jpg"
                />
              </div>
              <h2 className="grid-content-name">Suharti K. Suherman</h2>
              <h3 className="grid-content-title">Principal Investigator</h3>
            </div>
            <p className="grid-content-description font-justify">
              Suharti K. Suherman, MD, SpFK is a Principal Investigator at
              Pharma Metric Labs. She is also a Lecturer at Department
              Pharmacology, Investigator at Clinical Study Unit, Faculty of
              Medicine, University of Indonesia, Jakarta and a member of Drug's
              Adverse Event Monitoring Committee (MESO) of National Agency of
              Drug and Food Control (NADFC), Indonesia.
            </p>
          </div>
          {/* <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_virgo.jpg"
                />
              </div>
              <h2 className="grid-content-name">I Gusti Bagus Diana Virgo</h2>
              <h3 className="grid-content-title">President Director</h3>
            </div>
            <p className="grid-content-description font-justify">
              Drs. I Gusti Putu Bagus Diana Virgo, M.M., Apt. is an R&D
              professional with more than 20 years of experience in quality
              control, formulation development, packaging and standardization,
              analytical method development, pharmaceutical product development,
              chemical analysis techniques either conventional and instrumental
              analysis, microbiological analysis, and bio-analysis as well as
              bioequivalence study.
            </p>
          </div> */}
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_yuyus.jpg"
                />
              </div>
              <h2 className="grid-content-name">Yuyus Kusnadi</h2>
              <h3 className="grid-content-title">Head of Clinical Trial</h3>
            </div>
            <p className="grid-content-description font-justify">
              As the Head of Clinical Trial division, Yuyus Kusnadi, S.Si, PhD
              has numerous experience in handling clinical trial studies and its
              related documentation and is also a stem cell expert. With various
              publications, scientific research, and networks, he brings
              positive exposure to our team and projects.
            </p>
          </div>
          <div className="grid-content">
            <div className="grid-center">
              <div className="item-inner-image">
                <StaticImage
                  className="rounded-image"
                  src="../../../../images/tim_seriyati.jpg"
                />
              </div>
              <h2 className="grid-content-name">Seriyati Naibaho</h2>
              <h3 className="grid-content-title">Analytical Manager</h3>
            </div>
            <p className="grid-content-description font-justify">
              Seriyati Naibaho, S. Si has various experiences in the development
              and validation of bioanalytical methods, especially in BA / BE
              study and analytical instruments such as: HPLC, LCMSMS,
              spectrophotometry UV-VIS, and Dissolution tester. Graduated from
              Bogor Agricultural University in 2007, her expertise and technical
              knowledge ensure the validity and quality of our projects.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExpertAndTeam
